export const environment = {
  name: "Dev",
  production: false,
  appBasePath: "https://app.dev.jes-x.tech",
  apiBasePath: "https://api.dev.jes-x.tech/api/v1",
  passwordResetURL: "https://auth.dev.jes-x.tech/auth/realms/jestech/login-actions/reset-credentials?client_id=webapp",
  tokenEndpoint: "https://auth.dev.jes-x.tech/auth/realms/jestech/protocol/openid-connect/token",
  userInfoEndpoint: "https://auth.dev.jes-x.tech/auth/realms/jestech/protocol/openid-connect/userinfo",
  stompBrokerURL: "wss://stomp.dev.jes-x.tech/ws",
  keycloakAdmin: "https://auth.dev.jes-x.tech/auth/admin/master/console/#/realms/jestech/users/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
